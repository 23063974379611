@mixin line($size: 0) {
  &::after {
    @include pseudo;
    @include size(100%, 0);
    border-bottom: $border-light;
    bottom: 0.55em;
    margin-left: $spacing-xs;
    pointer-events: none;
    position: absolute;
    transform: scaleX($size);
    transform-origin: left;
    transition:
      transform $transition,
      border-color $transition;
    z-index: -1;
  }
}
