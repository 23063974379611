@import "../../../styles/base/abstracts";

.hbs-global-menu__nav {
  @include grid-child;
  height: 100%;
  overflow: visible; // needed to ensure submenu renders correctly
  position: relative;
  transition: transform $transition-md;

  @include mq($bp-tablet) {
    width: $col-8;

    .hbs-global-menu--nav-stack-2 & {
      transform: translateX(-100%);
    }
  }

  @include mq($bp-desktop-lg) {
    width: $col-6;

    .hbs-global-menu--nav-stack-2 & {
      transform: none;
    }
  }

  > ul {
    height: 100%;
    overflow-y: auto; // allow list to scroll if needed
    padding-bottom: 140px;
    padding-top: $menu-top-spacing + $spacing-xl;
  }

  &.hbs-global-menu__nav--banners-1 > ul {
    padding-top: $menu-top-spacing + 80px;
  }

  &.hbs-global-menu__nav--banners-2 > ul {
    padding-top: $menu-top-spacing + $spacing-xxxl;
  }
}

.hbs-global-menu__trigger,
.hbs-global-menu__link {
  @include h3($visually-align: false);
  align-items: center;
  color: $c-text-light;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: $transition;
  width: 100%;

  &:focus:not(:hover) {
    outline-offset: -2px !important;
  }

  @include hover {
    color: $c-text;

    &::after {
      border-color: $c-border;
    }

    .hbs-global-menu__trigger-icon {
      opacity: 1;
    }
  }

  .hbs-global-menu__item--active & {
    @include line(1);
    color: $c-text;

    .hbs-global-menu__trigger-icon {
      opacity: 0;
      transform: translateY(-0.1em) translateX($spacing-xs) scaleY(0);
    }
  }
}

.hbs-global-menu__trigger {
  @include line;

  @include hover {
    @include line(1);

    .hbs-global-menu__trigger-icon {
      opacity: 0;
      transform: translateY(-0.1em) translateX($spacing-xs) scaleY(0);
    }
  }
}

.hbs-global-menu__trigger-text,
.hbs-global-menu__link-text {
  @include padding($spacing-xxs x);
  position: relative;
  width: 95%;
}

.hbs-global-menu__bg {
  background-color: $c-bg;
  position: relative;
}

.hbs-global-menu__last-word {
  white-space: nowrap;
  > .hbs-global-menu__bg {
    padding-right: $spacing-sm;
  }
}

.hbs-global-menu__trigger-icon {
  @include size(0.5em);
  fill: none;
  transform: translateY(-0.02em);
  transition:
    transform $transition,
    opacity $transition;
  color: $c-border;
}
