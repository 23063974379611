@import "../../../styles/base/abstracts";

.hbs-global-menu {
  @include fixed(0);
  pointer-events: none;
  transform: translateY(-100%);
  visibility: hidden;
  z-index: $z-index-2;

  #docs-root & {
    position: relative;
  }

  //focus outline getting clipped by overflow settings
  .hbs-global-menu-list {
    padding-left: 2px;
    padding-right: 2px;
    margin-left: -2px;
  }
}

@include mq-reduced-motion {
  .hbs-global-menu {
    transition:
      visibility 0s linear $t-time-md,
      transform $transition-md;
  }
}

.hbs-global-menu--open {
  box-shadow: $box-shadow;
  border-bottom: $border-light;
  pointer-events: initial;
  transform: translateY(0);
  visibility: visible;
}

@include mq-reduced-motion {
  .hbs-global-menu--open {
    transition:
      visibility 0s linear 0ms,
      transform $transition-md;
  }
}

.hbs-global-menu__wrapper {
  @include grid-parent;
  @include site-max-width;
  flex-direction: column;
  height: 100vh;
  opacity: 0;

  @include mq-reduced-motion {
    transition: opacity $transition;
  }

  .hbs-global-menu--open & {
    opacity: 1;
  }
}

// Back button
.hbs-global-menu__back {
  @include color-link($c-text-light, $c-text);
  @include padding($spacing-sm);
  position: relative;
  align-items: center;
  background-color: $c-theme-solid-inverse;
  border-radius: $border-radius;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition;
  transition-delay: $t-time-md;
  width: auto;
  z-index: $z-index-3;
}

.hbs-global-menu__back--visible {
  opacity: 1;
  pointer-events: initial;
  transition-delay: 0s;
}

.hbs-global-menu__back-icon {
  @include size($caret-size-lg);
  margin-right: $spacing-xs;
  transform: rotateY(180deg);
}

// Social Media Icons
.hbs-global-menu__social {
  @include grid-child;
  @include padding($spacing-sm 0);
  @include fixed(x x -1px);
  background-color: $c-bg;
  opacity: 0;
  width: auto;

  &:before {
    @include absolute(x 0 100% 0);
    content: "";
    height: 25px;
    background: linear-gradient(transparent, $c-bg);
  }

  @include mq-reduced-motion {
    transition: opacity $transition;
    transition-delay: $t-time-md;
  }

  @include mq($bp-tablet) {
    @include padding(#{$grid-padding * 2} 0);
    width: $col-8;
  }

  @include mq($bp-desktop-lg) {
    width: $col-6;
  }

  .hbs-global-menu--open & {
    opacity: 1;
  }
}

// animated with inline styles - see use of styleTransitionDelay

@media (prefers-reduced-motion: no-preference) {
  .hbs-global-menu {
    .hbs-global-menu-list {
      .hbs-global-menu__link-text,
      .hbs-global-menu__submenu-link-text,
      .hbs-global-menu__trigger-text,
      .hbs-global-menu__submenu-trigger-text {
        transform: translateY(50%);
        transition-duration: 0.6s;
        transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        transition-property: transform;
      }
    }
  }
}
